import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { Container } from "../components/container"
import SEO from "../components/seo"

const GDPRPage = () => {
  const { t } = useTranslation();
 

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     pageURL: "/gdpr",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])

  return (
    <>
      <SEO
        title="Základní informace o zpracování osobních údajů a kontakty"
      />
      <Container className="terms">
        <h1 className="text-center">
        {t("GDPRPage.title")}
        </h1>

        <section>
          <Trans i18nKey="GDPRPage.text1"> 
            <p>
              Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41
              Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz,
              tel: +420 777 510 839 (dále jen „Provozovatel“) ve smyslu nařízení
              Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob
              v souvislosti se zpracováním osobních údajů a o volném pohybu těchto
              údajů (dále jen: „GDPR”) dbá zásad ochrany osobních údajů a
              zpracovává pouze ty osobní údaje, které slouží k poskytování služby
              vybrané zákazníkem a po dobu k tomuto účelu nezbytnou.
            </p>
            <p>
              Provozovatel zpracovává dále osobní údaje zástupců zákazníků
              (smluvních stran) a také potenciálních zákazníků, kteří mají zájem o
              služby Provozovatele. Účely zpracování osobních údajů jakož i
              informace o právních titulech, oprávněných zájmů a informace ohledně
              toho, že jsou osobní informace zpracovány pouze na základě
              svobodného a aktivního souhlasu klienta jsou vždy obsaženy v
              uzavřené smlouvě, nebo v obchodních podmínkách uveřejněných na webu
              Provozovatele. Ke zpracování osobních údajů dochází také za účelem
              plnění zákonných povinností Provozovatele vyvstávajících z daňového
              řádu, nebo jiného zákonného právního předpisu, i z jiných účelů,
              které sledují oprávněný zájem Provozovatele, tedy:
            </p>
          </Trans> 
          <Trans i18nKey="GDPRPage.list1">
            <ol>
              <li>
                evidence klientů, kteří byli kontaktováni obchodními zástupci
                Provozovatele v souvislosti s nabídkou na uzavření smlouvy, neb
              </li>
              <li>
                z důvodu předcházení podvodům, účely{" "}
                <b>kontroly nastavených technologických procesů</b>
              </li>
              <li>
                náboru nových zaměstnanců, kteří v rámci přijímací pohovoru sdělí
                Provozovatele své osobní údaje. Údaje neúspěšných uchazečů jsou
                uchovány maximálně po dobu 6 měsíců za účelem jejich případného
                užití spočívající v oslovení s jinou nabídkou zaměstnání/ účasti v
                jiném nebo opakovaném výběrovém řízení na obsazení pracovní
                pozice, nedohodnou-li se strany jinak,
              </li>
              <li>
                přímého marketingu, kterým se rozumí zpracování osobních údajů
                stávajících zákazníků za účelem přímého oslovení obchodní nabídkou
                nebo výzkumem. Provozovatel upozorňuje, že pokud zákazník podá u
                konkrétní služby námitku proti přímému marketingu, Provozovatel
                již nadále nebude nijak zpracovávat osobní údaje zákazníka za
                tímto účelem, stejně tak jako přestane zákazníka oslovovat/
                zasílat obchodní nabídky,
              </li>
              <li>výkon a obhajoba nároků Provozovatele, případně</li>
              <li>
                z důvodu výkonu a prosazování právních nároků v rámci reklamací,
                soudních sporů a výkonů rozhodnutí.
              </li>
            </ol>
          </Trans>
        </section>

        <section>
          <Trans i18nKey="GDPRPage.text2">
            <p>
              Informace obsažené v tomto dokumentu doplňují informace, které jsou
              zákazníkům poskytnuty v uzavírané smlouvě.
            </p>
            <p>
              <b>Totožnost a kontaktní údaje správce</b>
            </p>
            <p>
              Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41
              Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz,
              tel: +420 777 510 839
            </p>
            <p>
              <b>Kontaktní údaje Pověřence pro ochranu osobních údajů</b>
            </p>
            <p>
              Ve všech záležitostech souvisejících se zpracováním vašich osobních
              údajů je vám k dispozici náš pověřenec pro ochranu osobních údajů.
            </p>
            <p>
              Pověřence lze kontaktovat emailem na adrese info@nutritionpro.cz
            </p>
          </Trans>
        </section>

        <section>
          <Trans i18nKey="GDPRPage.text3">
            <p>
              <b>Poučení o právu podat námitky</b>
            </p>
            <p>
              <b>
                Ve všech případech zpracovávání osobních informací ze strany
                Provozovatele má klient právo podat proti takovému zpracování
                námitku. Námitka je posléze posouzena, a pokud pro to existují
                náležité zákonné důvody, bude ji vyhověno.
              </b>
            </p>
            <p>
              <b>Zabezpečení</b>
            </p>
            <p>
              <b>
                Všechny zpracovávané osobní údaje jsou zabezpečeny náležitými
                organizačně technických opatřeními.
              </b>
            </p>
            <p>
              <b>Příjemci osobních údajů</b>
            </p>
            <p>
              Příjemci osobních údajů je fyzická nebo právnická osoba, orgán
              veřejné moci, agentura nebo jiný subjekt, kterým jsou osobní údaje
              poskytnuty, ať už se jedná o třetí stranu, či nikoli. Orgány veřejné
              moci, které mohou získávat osobní údaje v rámci zvláštního šetření v
              souladu s právem členského státu, se za příjemce nepovažují;
              zpracování těchto osobních údajů těmito orgány veřejné moci musí být
              v souladu s použitelnými pravidly ochrany údajů pro dané účely
              zpracování.
            </p>
            <p>
              Provozovatel předává osobní údaje pouze následujícím příjemcům,
              kteří se podílejí se na dodání zboží / služeb / realizaci plateb na
              základě smlouvy, zajišťující marketingové služby.
            </p>
            <p>
              Správce nemá v úmyslu předat osobní údaje do třetí země (do země
              mimo EU) nebo mezinárodní organizaci.
            </p>
          </Trans>
        </section>
        <section>
          <Trans i18nKey="GDPRPage.text4">
            <p>
              <b>Doba, po které jsou osobní údaje uloženy</b>
            </p>
            <p>
              Základní doba, po kterou jsou osobní údaje uloženy, je vždy
              stanovená v uzavírané smlouvě mezi klientem a Provozovatelem.
            </p>
            <p>
              Osobní údaje potenciálních klientů, kteří předali své osobní údaje,
              ale smlouvu neuzavřeli, jsou osobní údaje uchovávány po dobu 1 roku,
              a to pro evidenční účely.
            </p>
            <p>
              Pro evidenční účely na dobu 10 let jsou uchovávány osobní údaje
              klientů od okamžiku ukončení účinnosti smlouvy.
            </p>
          </Trans>
        </section>
        <section>
          <Trans i18nKey="GDPRPage.text5">
            <p>
              <b>Ukládané údaje</b>
            </p>
            <p>
              <b>Kontaktní údaje:</b> Jméno, adresa, telefonní číslo, e-mailová
              adresa, ičo a to z toho důvodu, že zpracování je nezbytné pro
              splnění smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro
              provedení opatření přijatých před uzavřením smlouvy na žádost tohoto
              subjektu údajů, případně je zpracování nezbytné pro účely
              oprávněných zájmů příslušného správce či třetí strany, kromě
              případů, kdy před těmito zájmy mají přednost zájmy nebo základní
              práva a svobody subjektu údajů vyžadující ochranu osobních údajů.
              Účelem tak je, aby Provozovatel věděl, co po něm Klient požaduje a z
              jakého důvodu ho kontaktoval.
            </p>
            <p>
              <b>Lokalizační údaje:</b>
              Adresa, PSČ, město, stát.{" "}
              <b>
                Co do důvodů a účelu platí obdobně to, co je sděleno výše u
                kontaktních údajů, zejm. však pro účely doručování.
              </b>
            </p>
            <p>
              <b>Komunikační údaje:</b> Jméno, e-mailová adresa, telefonní číslo.{" "}
              <b>
                Co do důvodů a účelu platí obdobně to, co je sděleno výše u
                kontaktních údajů, zejm. však z důvodu mohl, aby mohl Provozovatel
                Klienta kontaktovat za účelem plnění dle smlouvy, s náležitým a
                uctivým oslovením, případně z důvodu řešení komplikací spojených s
                doručováním, nebo řešením reklamací.
              </b>
            </p>
            <p>
              <b>Platební údaje:</b> způsob platby.{" "}
              <b>
                Co do důvodů a účelu platí obdobně to, co je sděleno výše u
                kontaktních údajů, zejm. však, aby mohl Provozovatel sledovat
                platby a přiřadit je k řešeným objednávkám, případně k řešení
                reklamací.
              </b>
            </p>
            <p>
              <b>Dodací údaje:</b> Jméno, dodací adresa, telefonní číslo,
              identifikační číslo zákazníka.{" "}
              <b>
                Co do důvodů a účelu platí obdobně to, co je sděleno výše u
                kontaktních údajů, zejm. však dodání objednávek. Našim řidičům
                jsou předávány pouze nezbytné údaje pro doručení.
              </b>
            </p>
            <p>
              <b>Další ukládané údaje</b>
            </p>
            <p>
              Uchovávány jsou také veškeré další informace, osobní údaje, které
              klienti předají o jiných fyzických osobách, se kterými spolupracuje
              nebo jejichž údaje jsou pro plnění smluvního vztahu nezbytné. Tyto
              osoby je klient povinen obdobným způsobem informovat o předání
              jejich osobních údajů.
            </p>
            <p>
              <b>
                Stejně tak Provozovatel shromažďuje osobní údaje, které získá v
                průběhu telefonních hovorů, případně také na sociálních sítích
                (facebook, twitter, apod.). Telefonní hovor může být monitorován a
                nahráván za účelem zajištění kvality a řádného fungování našich
                služeb. Záznamy mohou být uchovány až šest měsíců pouze pro
                interní potřeby. Co do důvodů a účelu platí obdobně to, co je
                sděleno výše u kontaktních údajů.
              </b>
            </p>
          </Trans>
        </section>
        <section>
          <Trans i18nKey="GDPRPage.text6">
            <p>
              <b>Konkrétní účely zpracovávání údajů</b>
            </p>
            <p>
              <b>Řidiči/doručovatelé:</b> Těmto osobám, ať již jsou v
              zaměstnaneckém, nebo obdobném poměru, nebo se jedná o třetí stranu,
              která je pověřena doručením objednávky jsou předávány údaje nezbytné
              pro doručení objednávky. Kategorií osobních údajů jsou dodací údaje.
              Ve smyslu § 6 odst., písm. b), GDPR.
            </p>
            <p>
              <b>Přímý marketing:</b> Sdělením vaší emailové adresy souhlasíte se
              zasíláním našich obchodních nabídek a to prostřednictvím emailu.
              Zpracování osobních údajů (telefonních čísel, e-mailových adres
              apod.) zákazníků pro účely přímého marketingu (při rozesílce
              obchodních sdělení) je zpracování prováděné z důvodu oprávněného
              zájmu dle čl. 6 odst. 1 písm. f) GDPR. Informujeme tímto naše
              klienty o jejich právu vznést námitku dle čl. 21 odst. 2 GDPR proti
              zpracování osobních údajů pro účely přímého marketingu. Pokud Klient
              tuto námitku vznese, nesmějí již být osobní údaje pro tento účel
              zpracovány (jde tedy o tzv. princip opt-out), viz níže, odvolání
              svého souhlasu. <b>Kategorií osobních údajů jsou</b> kontaktní údaje
              a lokalizační údaje. V případě, že klient nesouhlasí od počátku se
              zasíláním obchodních sdělení, emailů, apod. výše, v rámci přímého
              marketingu, ať Provozovateli sdělí tento svůj požadavek v rámci své
              první objednávky, případně ať předem zašle toto sdělení na níže
              uvedenou emailovou adresu, viz. odvolání svého souhlasu.
            </p>
            <p>
              <b>Cílení (targeting).</b> Cílením se má přizpůsobování, tj.
              vypínání, aktivace a modulace reklamních bannerů na webových
              stránkách určitým cílovým skupinám za účelem co nejefektivnějším
              sdělení uživateli webu/ potenciálnímu zákazníkovi na
              individualizované bázi.
            </p>
            <p>
              Retargetting. Retargettingem se rozumí, pokud využití souborů cookie
              v případě opětovného navštívení webových stránek Provozovatele. Tato
              funkce může být deaktivována také deaktivací doplňků ve svém webovém
              prohlížeči, případně omezena až deaktivována mazáním souborů cookie.
              Kategorií osobních údajů jsou kontaktní údaje.
            </p>
            <p>
              Cookies. Cookie je krátký textový soubor, který si navštívený web
              ukládá ve vašem prohlížeči. Umožňuje webu zaznamenat informace o
              vaší návštěvě. Příští návštěva tak pro vás může být snazší a web
              bude užitečnější. Většina prohlížečů umožňuje ovládat způsob
              nastavení a používání souborů cookie při prohlížení a umožňuje
              smazat soubory cookie i údaje o prohlížení. Váš prohlížeč navíc
              možná nabízí správu souborů cookie na jednotlivých webech. K užívání
              cookies z naší strany můžete vyjádřit svůj nesouhlas a to patřičným
              sdělením na níže uvedený email, viz. Odvolání svého souhlasu. Vaši
              žádost vyřešíme bez zbytečného odkladu , ovšem Provozovatel
              upozorňuje na to, že tento nesouhlas bude mít svůj účinek pouze v
              rámci webového prohlížeče, ze kterého svůj nesouhlas vytvoříte.
            </p>
            <p>
              <b>Odvolání svého souhlasu</b> docílíte sdělením, že odvoláváte svůj
              souhlas se užitím osobních údajů na adresu info@nutritionpro.cz. Na
              tuto adresu posílejte také své případné NÁMITKY proti zpracování
              osobních údajů.
            </p>
          </Trans>
        </section>
        <section>
          <p>
            <b>{t("GDPRPage.text7")}</b>
          </p>
          <ol>
            <Trans i18nKey="GDPRPage.list2">
              <li>
                Za podmínek stanovených v GDPR máte
                <ul>
                  <li>
                    právo na přístup ke svým osobním údajům dle čl. 15 GDPR,
                  </li>
                  <li>
                    právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
                    zpracování dle čl. 18 GDPR.
                  </li>
                  <li>právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
                  <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a</li>
                  <li>právo na přenositelnost údajů dle čl. 20 GDPR.</li>
                  <li>
                    právo odvolat souhlas se zpracováním písemně nebo elektronicky
                    na adresu nebo email správce uvedený v těchto podmínkách.
                  </li>
                </ul>
              </li>
              <li>
                Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů
                v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu
                osobních údajů.
              </li>
            </Trans>
          </ol>
        </section>
        <section>
          <p>
            <b>{t("GDPRPage.text8")}</b>
          </p>
          <ol>
            <Trans i18nKey="GDPRPage.list3">
              <li>
                Odesláním objednávky z internetového objednávkového formuláře
                potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
                údajů a že je v celém rozsahu přijímáte.
              </li>
              <li>
                S těmito podmínkami souhlasíte zaškrtnutím souhlasu
                prostřednictvím internetového formuláře. Zaškrtnutím souhlasu
                potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
                údajů a že je v celém rozsahu přijímáte.
              </li>
              <li>
                Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek
                ochrany osobních údajů zveřejní na svých internetových stránkách,
                případně Vám zašle novou verzi těchto podmínek na e-mailovou
                adresu, kterou jste správci poskytl/a.
              </li>
              <li>
                Vyhrazujeme si právo toto prohlášení o ochraně osobních údajů
                změnit v souladu s právními předpisy. O případných významných
                změnách, jako jsou změny účelu nebo nové účely zpracování, vás
                budeme informovat.
              </li>
            </Trans>
          </ol>
        </section>
      </Container>
    </>
  )
}

export default GDPRPage
